import React from "react"
import SocialIcons from '../common/SocialIcons'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/hero-right-photo-text.scss'

class HeroLeftPhotoText extends React.Component {
  render() {
    return (
      <section className="hero-right-photo-text dec-section--box-top">
        <div className="container">
          <div className="row">
            <div className="col text-align-right">
                <SocialIcons type={"light"} position={"relative"} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-align-right">
              <h1 dangerouslySetInnerHTML={{ __html: this.props.heroHeader }} />
              <p dangerouslySetInnerHTML={{ __html: this.props.heroText }} className="h2" />
            </div>
            <div className="col-md-6 hero-right__image">
              <GatsbyImage image={this.props.heroImage} loading="auto" alt="" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroLeftPhotoText