import React from 'react'
import Helmet from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import SVG from 'react-inlinesvg'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroRightPhotoText from '../components/sections/hero-right-photo-text'
import SectionHeader from '../components/common/section-header'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-onlinemarketing.scss'
import { onlinemarketing as servicesSchema } from '../utils/schema/services'

const pageOnlineMarketing = ({ location }) => {
  const data = useStaticQuery(graphql`{
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-online-marketing"}}}) {
      ...GhostPageFields
    }
    hero: file(relativePath: {eq: "hero/hero-onlinemarketing.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 724, layout: CONSTRAINED)
      }
    }
    photo2: file(relativePath: {eq: "onlinemarketing/photo2.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    photo3: file(relativePath: {eq: "onlinemarketing/photo3.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 854, layout: CONSTRAINED)
      }
    }
    photo4: file(relativePath: {eq: "onlinemarketing/photo4.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    photo5: file(relativePath: {eq: "onlinemarketing/photo5.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 853, layout: CONSTRAINED)
      }
    }
    photo6: file(relativePath: {eq: "onlinemarketing/photo6.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    photo7: file(relativePath: {eq: "onlinemarketing/photo7.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    photo8: file(relativePath: {eq: "onlinemarketing/photo8.png"}) {
      childImageSharp {
        gatsbyImageData(width: 570, layout: CONSTRAINED)
      }
    }
    photo9: file(relativePath: {eq: "onlinemarketing/photo9.png"}) {
      childImageSharp {
        gatsbyImageData(width: 570, layout: CONSTRAINED)
      }
    }
    photo10: file(relativePath: {eq: "onlinemarketing/photo10.png"}) {
      childImageSharp {
        gatsbyImageData(width: 720, layout: CONSTRAINED)
      }
    }
    photo11: file(relativePath: {eq: "onlinemarketing/photo11.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
  `)
  const photo6 = getImage(data.photo6)
  const bgPhoto6 = convertToBgImage(photo6)
  const photo11 = getImage(data.photo11)
  const bgPhoto11 = convertToBgImage(photo11)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      </Helmet>
      <Layout>
        <HeroRightPhotoText
          heroImage={data.hero.childImageSharp.gatsbyImageData}
          heroHeader={'Online Marketing'}
          heroText={'Ignoring It<br> Is Like Opening<br> A Business But<br> Not Telling<br> Anyone'}
        />
        <section className="marketing__social marketing__box">
          <div className="container medium">
            <div className="row align-items-lg-center">
              <div className="col-md-6 order-2 order-md-1 col-no-padding marketing__photobox">
                <GatsbyImage
                  image={data.photo2.childImageSharp.gatsbyImageData}
                  alt="Corporate identity" />
              </div>
              <div className="col-md-6 order-1 order-md-2 marketing__textbox text-align-left">
                <h2 className="dec-header--underline">Social Media Strategy</h2>
                <strong className="h5">Facebook, Twitter, Pinterrest ή Instagram?<br /> Ή μήπως όλα?</strong>
                <p className="h6">Ακόμη αναρωτιέστε ποιά θα είναι η παρουσία σας στα social media?</p>
                <p>Ξεχάστε τα Facebook Page με ανύπαρκτο περιεχόμενο και τους λογαριασμούς Twitter με στερεότυπα τιτιβίσματα, και ελάτε σε μας να στήσουμε μία ολοκληρωμένη στρατηγική για social media που να ταιριάζει στο εταιρικό σας προφίλ. Στη Think Plus αναγνωρίζουμε τις ξεχωριστές σας ανάγκες για προβολή στα κοινωνικά μέσα δικτύωσης. Επιλέγουμε τα κατάλληλα Social Media για εσάς, που θα αυξήσουν την αλληλεπίδραση σας με το κοινό. Σχεδιάζουμε πρωτότυπες online marketing εκστρατείες που φέρνουν Likes, Followers και το μέγιστο δυνατό engagement!</p>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__reputation text-align-center">
          <div className="container">
            <h2 className="dec-header--underline">Reputation Management</h2>
            <p className="h4">Ποια είναι η διαδικτυακή σας «φήμη»;</p>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252 251"><g stroke="#313131" strokeWidth="2" fill="none" fillRule="evenodd"><path d="M135.293 146.34L72.81 169.082c-.516.188-1.22-.137-1.406-.65l-20.161-55.393c-.187-.513.143-1.213.66-1.401l62.482-22.742c.516-.188 1.22.137 1.406.65l20.161 55.392c-.143 1.214-.143 1.214-.66 1.402zM96.14 136.776l31.499-11.465M88.394 139.595l-8.262 3.008M66.265 157.524l-15.492 5.639-11.76-32.313 15.49-5.638M116.12 88.845l67.969-46.23c.516-.187 1.22.138 1.22.138l34.161 93.86c.187.512-.143 1.213-.846.888l-81.782 8.275M111.144 214.956c-1.032.376-1.735.05-1.922-.462l-18.855-51.802 22.205-8.082 11.76 32.312c4.294 11.797-1.827 23.899-13.188 28.034z"/><path d="M128.063 148.971l2.24 6.155c2.987 8.206-1.299 17.317-9.561 20.324M197.442 76.09c7.746-2.819 15.667 1.269 18.28 8.45 2.613 7.18-1.343 15.59-8.572 18.221"/><path d="M73.708 238.29c-29.55-13.209-53.392-37.263-65.039-69.262-11.647-32-8.844-65.751 5.302-94.864" strokeDasharray="1.9857,1.9857"/><path d="M238.98 179.208c-13.739 28.094-38.116 51.047-70.115 62.694-32 11.647-65.428 9.733-94.01-2.957"/><path d="M179.752 15.28c28.633 13.406 51.882 37.488 63.462 69.304 11.58 31.816 9.25 65.209-4.067 93.883" strokeDasharray="1.9857,1.9857"/><path d="M13.97 74.164c13.21-29.55 37.263-53.391 69.263-65.038 32-11.647 65.75-8.845 94.864 5.301"/></g></svg>
            <p>Τα Social Media αποτελούν ένα εξαιρετικά χρήσιμο εργαλείο για το online marketing σας. Η προσέλκυση fans και followers δίνει νέα δυναμική στην online παρουσία σας και αυξάνει το κοινό σας, και κατ’ επέκταση την αγορά σας. Όμως λάθη, αδυναμίες ή απλά η ΄κακιά στιγμή’ μπορούν να φέρουν τα ακριβώς αντίθετα αποτελέσματα από αυτά που περιμένατε. Τα αρνητικά σχόλια και οι κριτικές, ταξιδεύοντας αστραπιαία μέσα από το διαδικτυακό word of mouth, μπορούν να γκρεμίσουν σε ελάχιστο χρόνο τη διαδικτυακή σας φήμη!</p>
            <p>Πόσο προετοιμασμένοι είστε για μια κρίση που ξεσπά στα Social Media με εσάς στο προσκήνιο; Είστε έτοιμοι να αντιμετωπίσετε αρνητικά σχόλια και κριτική; Εμείς στη Think Plus αναλαμβάνουμε με επιτυχία το δικό σας reputation management. Εντοπίζουμε τις αδυναμίες της παρουσίας σας στα Social Media, καταστρώνουμε αποτελεσματικό online marketing σχέδιο για άμεση αντιμετώπιση μιας πιθανής κρίσης και διατηρούμε στο ακέραιο την διαδικτυακή σας φήμη.</p>
            <Link to="/contact-us/" className="btn__theme">Ρωτήστε μας περισσότερα</Link>
          </div>
        </section>
        <section className="marketing__monitoring">
          <div className="row no-gutters">
            <div className="col-md-5 dec-element--line-horizontal img">
              <GatsbyImage
                image={data.photo3.childImageSharp.gatsbyImageData}
                alt="Social media monitoring"
                className="img-md-cover" />
            </div>
            <div className="col-md-7 d-flex dec-element--line-horizontal">
              <div className="monitoring__content background__theme">
                <h2>Social Media Monitoring</h2>
                <strong className="h5">Έχετε Likes και Followers… <br />Έχετε όμως πετυχημένα Social Media?</strong>
                <p>Μήπως έφτασε η στιγμή να δώσετε στο δικό σας τμήμα marketing ένα πανίσχυρο όπλο;</p>
                <p>Εμείς στη Think Plus ελέγχουμε τις online marketing ενέργειες των δικών σας Social Media και ποσοτικοποιούμε τον βαθμό επιτυχίας! Το εξειδικευμένο μας προσωπικό διαχειρίζεται κατάλληλα εργαλεία δίνοντας σας τακτικά analytics για κάθε κοινωνικό μέσο δικτύωσης που έχετε παρουσία. ‘Ακούστε’ σε αριθμούς τις φωνές του διαδικτυακού κοινού σας. Δείτε πως απεικονίζονται σε γραφήματα τα Likes, τα Comments και τα Shares.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__facebook">
          <GatsbyImage
            image={data.photo4.childImageSharp.gatsbyImageData}
            alt="Facebook marketing" />
          <div className="container medium">
            <div className="row">
              <div className="col-md-6">
                <div className="facebook__application dec-element--line-vertical">
                  <h2 className="h3">Facebook Application Development</h2>
                  <strong className="h5">Το hopping στα Social Media δεν είναι αρκετό για σας;</strong>
                  <p>Εμείς υλοποιούμε την ιδέα και εσείς κερδίζετε Engagement, Engagement, Engagement!</p>
                  <p>Μα τότε χρειάζεστε Facebook App! Εμείς στη Think Plus έχουμε το know how για πετυχημένες εφαρμογές Facebook. Πρωτότυπο σενάριο, εντυπωσιακά εικαστικά και viral διάθεση είναι τα βασικά μας συστατικά για ένα πετυχημένο Facebook App. Sweepstake, Giveaway, Trivia ή μήπως Quiz;</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="facebook__ads dec-element--line-vertical">
                  <h2 className="h3">Facebook Ads - Be Discovered.</h2>
                  <strong className="h5">Κερδίστε Likes και Engagement άμεσα και οικονομικά!</strong>
                  <p>Impressions ή CPC; Προώθηση του Fanpage ή της σελίδας; Target Group; Είμαστε εδώ για να οργανώσουμε την κατάλληλη διαφημιστική εκστρατεία για τη σελίδα σας στο Facebook με το μικρότερο δυνατό κόστος. Εντυπωσιακά εικαστικά, πρωτότυπο περιεχόμενο και κατάλληλη στόχευση είναι τα τρία συστατικά των Facebook Ads με τη Think Plus.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="facebook__marketing">
                  <h2 className="h3 dec-element--line-horizontal">Facebook marketing - Let's Get Social!</h2>
                  <strong className="h5">Ελάτε να σχεδιάσουμε μαζί το πακέτο που σας ταιριάζει &amp; Let’s Get Social από 100 ευρώ το μήνα.</strong>
                  <p>Η ναυαρχίδα των Social Media είναι αυτή που θα απογειώσει το εταιρικό σας προφίλ. Αυξήστε Likes, αλληλεπιδράστε με τους Fans σας και διευρύνετε το κοινό σας. Πώς; Μα με τη Think Plus φυσικά! Είμαστε από τους πρώτους που εισάγαμε το social media marketing στην Ελλάδα και έχουμε το know-how για πετυχημένες Facebook σελίδες.</p>
                  <p>Σχεδιάζουμε Post με εντυπωσιακά εικαστικά και πρωτότυπα κείμενα, οργανώνουμε Facebook Ads εκστρατείες και σχεδιάζουμε το App που σας ταιριάζει για τους διαγωνισμούς σας! Εγγυόμαστε την αύξηση των Likes και της αλληλεπίδρασης της Facebook σελίδας από την 1η κιόλας μέρας που θα αναλάβουμε τον λογαριασμό σας!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__social-media background__theme--dark">
          <div className="container medium">
            <div className="row">
              <div className="col">
                <h2>Twitter marketing</h2>
                <div className="social-media__icon-title d-flex align-items-center">
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                  <strong className="h5">Πόσα μπορείτε να πείτε με μόλις 280 χαρακτήρες;</strong>
                </div>
                <p>Εμείς στη Think Plus μπορούμε να ‘τιτιβίσουμε’ τόσο ώστε να απογειώσουμε το online marketing της εταιρείας σας! Δημιουργούμε κατάλληλα tweets, κερδίζουμε νέους followers και κρατάμε αμείωτο το ενδιαφέρον των υπαρχόντων.</p>
                <p>Ελάτε να σχεδιάζουμε μαζί το δικό σας Twitter Marketing, και μεγαλώστε το διαδικτυακό σας κοινό μέσα από το πιο ‘αεράτο’ Social Media!</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2>LinkedIn marketing</h2>
                <div className="social-media__icon-title d-flex align-items-center">
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                  <strong className="h5">Εσείς έχετε παρουσία στο ‘Facebook’ των επαγγελματιών;</strong>
                </div>
                <p>Σχεδιάζουμε και υλοποιούμε το δικό σας LinkedIn Marketing και απογειώνουμε το branding της επιχείρησής σας. Συνδέουμε την επιχείρησή σας στον διαδικτυακό κόσμο των επαγγελματιών όπου σας περιμένουν νέες συνεργασίες και B2B ευκαιρίες.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2>Pinterest marketing</h2>
                <div className="social-media__icon-title d-flex align-items-center">
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"></path></svg>
                  <strong className="h5">Είναι το φρέσκο Social Media που τρέχει με χίλια!</strong>
                </div>
                <p>Είναι το φρέσκο Social Media που τρέχει με χίλια! Και γιατί όχι αφού στηρίζεται σχεδόν εξ ολοκλήρου σε εικόνες! Εμείς σχεδιάζουμε τον δικό σας good looking account, ανεβάζουμε το πιο creative περιεχόμενο, κερδίζουμε followers και εσείς επωφελείστε από την αλληλεπίδραση με τους Pinners.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2>Youtube marketing</h2>
                <div className="social-media__icon-title d-flex align-items-center">
                  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                  <strong className="h5">Πιο viral δεν γίνεται!</strong>
                </div>
                <p>Λέγεται Youtube, έχει αντικαταστήσει την old-fashioned TV και είναι πάνω από όλα viral! Εμείς στη Think plus, φτιάχνουμε το δικό σας κανάλι στο Youtube στο ύφος που σας ταιριάζει. Ανεβάστε τα video σας online, κερδίστε views, likes και επωφεληθείτε από τα comments των θεατών σας για δυνατό engagement.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__google">
          <div className="row no-gutters">
            <div className="col-md-7 d-flex dec-element--line-horizontal">
              <div className="google__content background__theme text-align-right">
                <h2>Google Adwords</h2>
                <p>Εμείς στη Think plus αναλαμβάνουμε τη δική σας Adwords διαφημιστική εκστρατεία και εγγυόμαστε γρήγορα και άμεσα αποτελέσματα. Ορίζουμε τις λέξεις-κλειδιά, δημιουργούμε πρωτότυπα κείμενα με δυνατό Call for action, σχεδιάζουμε τα εικαστικά (ναι υπάρχουν και αυτά στο Adwords!) και σας υποσχόμαστε ένα ισχυρό CTR! Σχεδιάζουμε την διαφημιστική σας εκστρατεία στο Google και δημιουργούμε το κατάλληλο Landing page για την προωθητική σας ενέργεια. Φροντίζουμε να έχετε χαμηλά κόστη CPC και CPM, διατηρώντας σε υψηλά επίπεδα τα κλικ στη σελίδα σας!</p>
                <strong className="h5">Βρείτε τώρα τον δρόμο σας στη μεγαλύτερη μηχανή αναζήτησης και κερδίστε Impressions και Clicks!</strong>
              </div>
            </div>
            <div className="col-md-5 dec-element--line-horizontal img">
              <GatsbyImage
                image={data.photo5.childImageSharp.gatsbyImageData}
                alt="Google adwords"
                className="img-md-cover" />
            </div>
          </div>
        </section>
        <BackgroundImage
          Tag='section'
          className='marketing__seo text-align-center'
          {...bgPhoto6}
        >
          <div className="container medium">
            <div className="row">
              <div className="col">
                <SectionHeader
                  title={'SEO'}
                  text={'Search Engine Optimization'}
                  />
                <p>Αυξήστε την επιρροή της ιστοσελίδας σας στο Google και το Bing με το SEO της Think plus. Εφαρμόζουμε τις κατάλληλες τεχνικές Search Engine Optimization και βελτιστοποιούμε τη θέση σας στις μηχανές αναζήτησης.</p>
                <p>Οι On-page SEO τεχνικές μας θα μεταμορφώσουν το στήσιμο της ιστοσελίδας σε user-friendly δομή για τις μηχανές αναζήτησης, ενώ τα backlinks που δημιουργούμε στα πλαίσια του Off-page SEO θα φέρουν την ιστοσελίδα σας στη θέση που της αξίζει: Στην κορυφή!</p>
                <Link to="/contact-us/" className="btn__theme">Ρωτήστε μας περισσότερα</Link>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section className="marketing__display background__theme--dark">
          <div className="container medium">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="h1 text-align-right">
                  <div>ONLINE</div>
                  <span className="h1 dec-element--line-horizontal">&nbsp;Display<br /> ADVERTISING</span>
                </h2>
              </div>
              <div className="col-md-6">
                <strong className="h6">Προβάλετε το brand σας με banner ή δημιουργικό rich media σε άλλες σελίδες με μεγάλη κίνηση. Πρόκειται για τον πλέον παραδοσιακό τρόπο online marketing που ενδυναμώνει την διαδικτυακή σας παρουσία και αυξάνει την επισκεψιμότητα στο site σας. </strong>
                <p>Εμείς στη Think Plus δημιουργούμε ελκυστικά και πρωτότυπα banners, προσεγγίζουμε το κατάλληλο target group για την περίπτωσή σας, και επιλέγουμε τα κατάλληλα sites που θα φιλοξενηθεί η διαφήμιση σας. Παρακολουθούμε διαρκώς την πορεία της εκστρατείας και πραγματοποιούμε συνεχώς βελτιώσεις για να φέρουμε σε σας τα καλύτερα αποτελέσματα.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__email marketing__box">
          <div className="container medium">
            <div className="row align-items-center">
              <div className="col-md-6 col-no-padding marketing__photobox">
                <GatsbyImage image={data.photo7.childImageSharp.gatsbyImageData} alt="Email marketing" />
              </div>
              <div className="col-md-6 marketing__textbox text-align-left">
                <h2 className="dec-header--underline">Email marketing</h2>
                <p>Προωθείτε νέα προϊόντα; Θέλετε να αναθερμάνετε τις σχέσεις σας με τους πελάτες; Ή μήπως απλά έχετε σούπερ νέα που θέλετε να μοιραστείτε με το κοινό σας; Για όλα τα παραπάνω, η λύση είναι η ανάπτυξη μιας εκστρατείας Email! Εμείς στη Think plus σχεδιάζουμε τα Newsletter σας και ισχυροποιούμε το engagement με το πελατολόγιο σας. Αναλαμβάνουμε από το σχεδιασμό του template και τη δημιουργία του περιεχομένου μέχρι την μαζική αποστολή των emails.</p>
                <strong className="h5">Επιλέξτε τη Think Plus και επωφεληθείτε από την άμεση επικοινωνία και το χαμηλό κόστος του Email marketing.</strong>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__strategy text-align-center">
          <div className="container medium">
            <div className="row">
              <div className="col">
                <h2 className="dec-header--underline">Internet Strategy</h2>
                <p>Μπορεί η κατασκευή απλά μιας ιστοσελίδας την προηγούμενη 10ετία να ήταν αρκετή για την διαδικτυακή σας εταιρική ταυτότητα, όμως δεν συμβαίνει το ίδιο σήμερα. Social Media, Blogs, Mobile Apps κ.α. έχουν αλλάξει ριζικά τους κανόνες στη στρατηγική μάρκετινγκ. Εμείς στη Think plus αναλαμβάνουμε το δικό σας Internet Strategy με ολιστική προσέγγιση στις δυνατότητες του διαδικτύου. Αναλύουμε την υφιστάμενη κατάσταση της διαδικτυακής σας παρουσίας, αξιολογούμε τις δυνατότητες βελτίωσης, καθορίζουμε βραχυπρόθεσμούς και μακροπρόθεσμους στόχους και καταρτίζουμε το νέο σχέδιο της διαδικτυακής σας στρατηγικής. Παρακολουθούμε διαρκώς την πορεία του σχεδίου δράσης και φροντίζουμε να έχετε πάντοτε τα επιθυμητά αποτελέσματα.</p>
                <strong className="h5">Εμπιστευτείτε μας τώρα για το σχεδιασμό του δικού σας Internet Strategy και αλλάξτε σελίδα στη διαδικτυακή σας παρουσία!</strong>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__viral background__theme">
          <div className="container medium">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="viral__textbox text-align-right">
                  <h2 className="dec-element--line-horizontal">Online Διαφήμιση</h2>
                  <p>Το διαδίκτυο αποτελεί το μοναδικό μέσο που μπορείτε να στοχεύσετε το κοινό σας με ακρίβεια. Εμείς στη Think plus αναγνωρίζουμε τις ανάγκες σας και οργανώνουμε τις κατάλληλες online διαφημιστικές εκστρατείες ώστε να μη χάσετε ούτε ένα cent! Επιλέγουμε το κατάλληλο μέσο προώθησης που ταιριάζει στην περίπτωσή σας (μηχανές αναζήτησης, social media, display σε άλλες σελίδες κ.α.) και σχεδιάζουμε από την αρχή την τη δική σας online διαφήμιση με πρωτότυπα κείμενα και γραφικά. Βρίσκουμε τις κατάλληλες λέξεις-κλειδιά, προσεγγίζουμε το σωστό target group και παρακολουθούμε διαρκώς την πορεία της καμπάνιας. Τα reports που θα σας παραδίδουμε ανά τακτά διαστήματα θα σας δείχνουν μόνο αυξημένα κλικ και Impressions!</p>
                  <strong className="h5">Επικοινωνήστε μαζί μας για να σχεδιάσουμε τη δική σας πετυχημένη online διαφήμιση!</strong>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <GatsbyImage
                  image={data.photo8.childImageSharp.gatsbyImageData}
                  alt="Online διαφήμιση" />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <GatsbyImage image={data.photo9.childImageSharp.gatsbyImageData} alt="Viral marketing" />
              </div>
              <div className="col-md-6">
                <div className="viral__textbox">
                  <h2 className="dec-element--line-horizontal">Viral Marketing</h2>
                  <p>Γνωστό και ως buzz ή ripple, αποτελεί το ‘μπαμ’ του μαρκετινγκ και είναι σίγουρα viral! Το χαρακτηριστικό μιας viral campaign είναι ότι μέσα σε ελάχιστο χρόνο γίνεται γνωστή σε πολύ μεγάλο κοινό για σύντομο χρονικό διάστημα. Κάτι σαν τα 15 λεπτά διασημότητας του Γούρχολ που όμως ΄σπάνε΄ κυριολεκτικά τα ταμεία. To δημιουργικό τμήμα της Think plus έχει το know-how για viral εκστρατείες. Μία ατάκα, μία εικόνα ή ένα βίντεο που λανσάρεται από το κατάλληλο μέσο, την κατάλληλη στιγμή θα κάνει το δικό σας προωθητικό ‘μπαμ’.</p>
                  <strong className="h5">Επικοινωνήστε μαζί μας για να σχεδιάσουμε τη δική σας πετυχημένη online διαφήμιση!</strong>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__interactive background__theme--dark">
          <div className="container medium">
            <div className="row align-items-center">
              <div className="col-md-6 text-align-right">
                <p>Αν ψάχνετε εναλλακτικούς τρόπους για την διαδικτυακή σας προώθηση, προτείνουμε ανεπιφύλακτα ένα διαδραστικό παιχνίδι. Τα Interactive games, τόσο για desktop όσο και για mobile συσκευές, αποτελούν τη νέα τάση στο online marketing, και υπόσχονται ισχυρό engagement!</p>
                <strong className="h5">Εμείς στη Think plus κατασκευάζουμε το δικό σας Interactive game και δίνουμε νέα πνοή στη διαδικτυακή σας παρουσία.</strong>
              </div>
              <div className="col-md-6">
                <h2 className="h1">
                  <div className="dec-element--line-horizontal">INTERA<br />CTIVE</div>
                  <span className="h1">&nbsp;Games</span>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="marketing__mobile">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <GatsbyImage
                  image={data.photo10.childImageSharp.gatsbyImageData}
                  alt="Mobile SMS marketing" />
              </div>
              <div className="col-md-5">
                <h2>Mobile Marketing - <br />Mobile Application Development</h2>
                <p>Οι ασύλληπτες δυνατότητες που χαρακτηρίζουν τα smartphone σε συνδυασμό με την ευρεία τους χρήση, αποτελούν το κατάλληλο περιβάλλον για να αναπτύξετε την επόμενη διαφημιστική σας εκστρατεία. Banners στα mobile portals, minisites, content search και mobile applications είναι μόνο κάποιες από τις εφαρμογές που υλοποιεί η Think plus για το δικό σας Mobile Marketing.</p>
                <strong className="h6">Επικοινωνήστε μαζί μας για να σχεδιάσουμε το Mobile Marketing, που να ταιριάζει ειδικά για το εταιρικό σας προφίλ.</strong>
                <hr className="hr--theme" />
                <h2>SMS Marketing</h2>
                <p>Έχετε νέα προϊόντα; Θέλετε να ενημερώσετε άμεσα το κοινό σας; Ή μήπως σκέφτεστε κάποιο γρήγορο διαγωνισμό με δυνατό engagement; Ο αμεσότερος τρόπος για μαζική αλληλεπίδραση με το κοινό σας είναι η υλοποίηση μιας καμπάνιας με μαζικά SMS. Εμείς στη Think plus, δημιουργούμε στοχευμένες sms εκστρατείες με ελκυστικό περιεχόμενο για την προώθηση της επιχείρησής σας. Στοχεύουμε σε ισχυρή αλληλεπίδραση με το κοινό σας, ενώ παράλληλα διατηρούμε το οικονομικό κόστος για σας σε χαμηλά επίπεδα.</p>
                <Link to="/contact-us/" className="btn__theme">Καλέστε μας σήμερα</Link>
              </div>
            </div>
          </div>
        </section>
        <BackgroundImage
          Tag='section'
          className='marketing__flip'
          {...bgPhoto11}
        >
          <div className="container medium">
            <div className="row">
              <div className="col-md-7">
                <h2>Flip Books - Interactive content</h2>
                <p>Τα flip books αποτελούν τον πιο pop τρόπο για την προώθηση του content marketing, ξεπερνώντας τα παραδοσιακά websites και τα κουραστικά blogs. Με λειτουργία τόσο online όσο και offline, τα flip books είναι ιδανικά για εφαρμογές e-περιοδικού, διαφημιστικού κατάλογου, εταιρικά events, παρουσίαση της εταιρικής σας ταυτότητας. Εμείς στη Think plus σχεδιάζουμε ελκυστικά template με πρωτότυπο περιεχόμενο που θα αυξήσουν κατά κόρον την αλληλεπίδραση με το κοινό σας. Δίνουμε διαδραστικό ‘αέρα’ με πρωτότυπες εφαρμογές στο flip book, κρατώντας αμείωτο το ενδιαφέρον του αναγνώστη μέχρι την τελευταία σελίδα.</p>
                <strong className="h5">Επικοινωνήστε μαζί μας για να σχεδιάσουμε το Mobile Marketing, που να ταιριάζει ειδικά για το εταιρικό σας προφίλ.</strong>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section className="marketing__widget background__theme text-align-center">
          <div className="container medium">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273 273"><g transform="translate(8 1)" fill="#FFA55D" fillRule="evenodd"><rect y="158" width="108" height="108" rx="3"/><rect y="23" width="108" height="108" rx="3"/><rect x="130" y="158" width="108" height="108" rx="3"/><rect transform="rotate(45 184 77)" x="130" y="23" width="108" height="108" rx="3"/></g></svg>
            <h2>Κατασκευή widget</h2>
            <p>Ενισχύστε το brand awareness της επιχείρησής σας με τη δική σας εφαρμογή widget. Desktop ή mobile, ένα πετυχημένο widget γίνεται άμεσα viral με το που φιλοξενηθεί στα social media. Εμείς σχεδιάζουμε την κατάλληλη widget εφαρμογή ώστε να πετύχετε την καλύτερη αλληλεπίδραση με το κοινό σας. Αναλαμβάνουμε τα template, το περιεχόμενο καθώς και τις online marketing υποστηρικτικές ενέργειες για την προώθησή του (ενσωμάτωση στο website, προώθηση με email campaigns, blogs).</p>
          </div>
        </section>
        <ContactForm />
      </Layout>
    </>;
}

export default pageOnlineMarketing
