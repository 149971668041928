import React from 'react'

import '../../styles/components/section-header.scss'

class SectionHeader extends React.Component {
  render() {
    return (
      <header className="section-header">
        {
          this.props.h1
            ? <h1 className="h2" aria-label={this.props.title}><span className="visually-hidden">{this.props.title}</span></h1>
            : <h2 aria-label={this.props.title}><span className="visually-hidden">{this.props.title}</span></h2>
        }
        <p dangerouslySetInnerHTML={{ __html: this.props.text}} />
      </header>
    )
  }
}

export default SectionHeader